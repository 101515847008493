.site-title {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 35px;
    color: #202029;
    margin-top: 5px;
    margin-bottom: 0;
}

.site-title span {
    color: #5838fc;
}

.site-heading p {
    font-size: 16px;
}

.copyright {
    margin-top: 60px;
    margin-bottom: 20px;
    padding: 22px 0 46px;
}

.copyright p {
    font-size: 13px;
    line-height: 26px;
    color: #767480;
    margin: 0;
}

.copyright p a {
    color: #5838fc;
}

.copyright p a:hover {
    color: #767480;
}

a {
    color: #2c234d;
    text-decoration: none;
}

a:hover {
    color: #5838fc;
    text-decoration: none;
}

a:focus {
    outline: 0;
    text-shadow: none;
    box-shadow: none;
}

.theme-btn {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    padding: 12px 25px;
    border-radius: 5px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-transform: capitalize;
    position: relative;
    cursor: pointer;
    text-align: center;
    border: none;
    background: #5838fc;
    box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
}

.theme-btn:hover {
    background: #202029;
    color: #fff;
}
